<template>
  <BaseGuestRoomDailyReport>
    <template v-slot:default>
      <v-col cols="12" lg="6" class="pt-0">
        <div class="mx-5 d-flex align-center">
          <span class="main_txt mr-2">年度</span>
          <v-select
            @change="statusShowYear = false"
            v-model="yearSelected"
            :items="years"
            class="main_select"
            clearable
          ></v-select>
        </div>
        <v-form ref="holidays" lazy-validation class="main_content">
          <v-data-table
            :headers="headers"
            :items="holidays"
            class="table-custom"
            hide-default-footer
            :no-data-text="$t('rules.noData')"
            disable-pagination
          >
          <template v-slot:[`item.name`]="{ item }">
            <v-text-field
              v-model="item.name"
              :rules="rules.name"
              :disabled="!checkUser"
              type="text"
              class="main_input"
            ></v-text-field>
          </template>
          <template v-slot:[`item.holidayDate`]="{ item }">
            <v-menu
              ref="menu"
              v-model="item.active"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatDate(item.holidayDate)"
                  :disabled="!checkUser"
                  prepend-icon="mdi-calendar-month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="main_input" :class="item.id"
                ></v-text-field>
              </template>
              <v-date-picker
                v-if="checkUser"
                v-model="item.holidayDate"
                no-title
                scrollable
                @input="item.active = false"
                class="v-date-picker-custom"
                :locale="$i18n.locale"
              >
                <!-- <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="item.active = false"
                >
                  {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.item.active.save(item.holidayDate)"
                >
                  {{ $t('buttons.saveAndCreate') }}
                </v-btn> -->
              </v-date-picker>
            </v-menu>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              :disabled="!checkUser"
              @click="deleteItem(item)"
              class="main_icon color-main"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          </v-data-table>
          <div
            class="d-flex flex-wrap pt-3 px-4"
          >
            <v-btn
              class="mb-2 mr-2 btn-custom"
              outlined
              color="primary"
              @click="addHolidays"
              :disabled="!checkUser"
            >
              <v-icon small>mdi-plus</v-icon>
              {{ $t('buttons.add') }}
            </v-btn>
            <v-btn
              @click="addMoreYear"
              :disabled="statusShowYear"
              class="mb-2 btn-custom"
              outlined
              color="primary"
            >
              <v-icon small>mdi-plus</v-icon>
              前年度の祝日を読み込む
            </v-btn>
          </div>
          <v-card-actions>
            <v-btn
              @click="save"
              :disabled="!checkUser"
              color="primary"
              class="ml-auto"
            >
              {{ $t('buttons.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-col>
    </template>
  </BaseGuestRoomDailyReport>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import moment from 'moment'
import BaseGuestRoomDailyReport from '@/components/GuestRoomDailyReport/BaseGuestRoomDailyReport'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'HolidaySetting',
  components: {
    BaseGuestRoomDailyReport
  },
  mounted () {
    this.getYearList()
    this.fetchHolidays()
  },
  data () {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      statusShowYear: false,
      enabled: null,
      menu: false,
      yearAdded: 0,
      years: [],
      headers: [
        { text: '祝日名', value: 'name' },
        { text: '日付', value: 'holidayDate' },
        { text: '', value: 'actions', sortable: false }
      ],
      rules: {
        name: [
          v => !!v || this.$t('rules.isRequired'),
          v => v?.length < 52 || this.$t('rules.isInvalid')
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['errorDialogMessages', 'holidays', 'currentYear']),
    yearSelected: {
      get () {
        return this.currentYear
      },
      set (value) {
        this.setCurrentYear(value)
        this.setHolidayStartYear(value)
        this.fetchHolidays()
      }
    }
  },
  methods: {
    ...mapActions(['setHolidays', 'fetchHolidays']),
    ...mapMutations(['setErrorDialog', 'setHolidayStartYear', 'setCurrentYear']),
    save () {
      if (this.$refs.holidays.validate()) {
        const holidaysInYear = this.holidays.filter(item => new Date(item.holidayDate).getFullYear() === this.yearSelected)
        if (holidaysInYear.length > 0) {
          const uniqueValues = new Set(holidaysInYear.map(v => v.holidayDate))
          if (uniqueValues.size < holidaysInYear.length) {
            this.setErrorDialog(this.errorDialogMessages.duplicateDates)
          } else {
            this.setHolidays({ year: this.yearSelected, holidays: holidaysInYear })
          }
        } else {
          this.setHolidays({ year: this.yearSelected, holidays: holidaysInYear })
        }
      }
    },
    getYearList () {
      const minYear = new Date().getFullYear() - 10
      for (let i = 0; i < 21; i++) {
        this.years.push(minYear + i)
      }
    },
    addMoreYear () {
      this.statusShowYear = true
      this.setHolidayStartYear(this.yearSelected - 1)
      this.fetchHolidays({ fetchMore: true })
    },
    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      return moment(value).format('yyyy年MM月DD日 (' + d + ')')
    },
    deleteItem (item) {
      const idx = this.holidays.indexOf(item)
      if (idx > -1) {
        this.holidays.splice(idx, 1)
      }
    },
    addHolidays () {
      var day = new Date(this.yearSelected, 0, 2).toISOString().substr(0, 10)
      this.holidays.push({
        name: '',
        holidayDate: day
      })
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep {
    .v-messages__message {
      margin-top: 4px !important;
      font-size: 12px !important;
    }
  }
</style>
