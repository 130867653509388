<template>
  <div>
    <h1 class="py-4 page-title-main header-main">
      {{ $t("routeTitles.guestRoomDailyReport.guestRoomDailyReport") }}
    </h1>
    <template>
      <div class="px-main">
        <v-tabs v-model="tab">
          <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
        <slot name="default" />
      </div>
    </template>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'

export default {
  name: 'BaseGuestRoomDailyReport',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: this.$t('routeTitles.guestRoomDailyReport.guestRoomPanel'),
          to: '/guest-room-daily-report/guest-room-panel',
          pathRoot: '/guest-room-daily-report/guest-room-panel',
          order: 1
        },
        {
          title: this.$t('routeTitles.guestRoomDailyReport.dailyReport'),
          to: '/guest-room-daily-report/daily-report',
          pathRoot: '/guest-room-daily-report/daily-report',
          order: 2
        },
        {
          title: this.$t('routeTitles.guestRoomDailyReport.dailyReportList'),
          to: '/guest-room-daily-report/daily-report-list',
          pathRoot: '/guest-room-daily-report/daily-report-list',
          order: 3
        },
        {
          title: this.$t('routeTitles.guestRoomDailyReport.holidaySetting'),
          to: '/guest-room-daily-report/holiday-setting',
          pathRoot: '/guest-room-daily-report/holiday-setting',
          order: 4
        }
      ]
    }
  },
  mounted () {
    this.checkPerTab()
  },
  components: {},
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
}
</script>

<style lang="scss">
  .v-tab{
    color: #aaa !important;
    font-weight: 600;
    font-size: 16px !important;
  }
  .v-tab--active{
    color: #1873D9 !important;
}
</style>
